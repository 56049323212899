import PropTypes from 'prop-types';
/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import Card from '../../atoms/Card/Card';
import ConfettiAnimated from './assets/confetti-animated.svg';
import { Heading1 } from '../../atoms/Headings/Headings';
import Image from '../../atoms/Image/Image';
import Link from '../../atoms/Link/Link';
import Section from '../../molecules/Section/Section';
import Text from '../../atoms/Text/Text';
import {
  breakpoints,
  colors,
  fontSizes,
  pageDimensions,
  unitConverter as uc,
} from '../../../styles/base';

const ThankYouMessage = ({ heading, subtitle }) => {
  const sectionCss = css`
    display: flex;
    background: url('/thankYouBanner/ThankYou_Page_header-transparent.png')
      center top no-repeat;
    background-size: ${pageDimensions.largeDesktopWidth};

    &:first-of-type {
      z-index: 3;
    }

    @media (${breakpoints.desktop}) {
      padding: ${uc('0 20px')};
    }
  `;

  const thankYouBoxCss = css`
    display: flex;
    flex-direction: column;
    max-width: ${uc('1000px')};
    margin: ${uc('180px')} auto ${uc('75px')};
    padding: ${uc('80px 180px')};
    border-radius: ${uc('15px')};

    @media (${breakpoints.mobile}) {
      max-width: 100%;
      padding: ${uc('80px 70px')};
    }
  `;

  const svgCss = css`
    max-width: ${uc('150px')};
    margin: auto;
  `;

  const textDivCss = css`
    max-width: ${uc('475px')};
    margin-top: ${uc('25px')};
    text-align: center;
  `;

  const subtexts = subtitle ? (
    <Text fontSize={fontSizes.sixteen}>{subtitle}</Text>
  ) : (
    // this is for phase 2, not ready yet
    <Link to="www.google.com">Not Downloading?</Link>
  );

  return (
    <Section css={sectionCss}>
      <Card css={thankYouBoxCss}>
        <Image src={ConfettiAnimated} alt="celebration confetti" css={svgCss} />
        <div css={textDivCss}>
          <Heading1 color={colors.darkGray.three}>{heading}</Heading1>
          {subtexts}
        </div>
      </Card>
    </Section>
  );
};

ThankYouMessage.propTypes = {
  heading: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

ThankYouMessage.defaultProps = {
  subtitle: null,
};

export default ThankYouMessage;
