import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import Layout from '../../organisms/Layout/Layout';
import useSanityFetchDocuments from '../../../hooks/useSanityFetchDocuments';
import sanityClient from '../../../utils/sanityClient';
import useLocalize from '../../../hooks/useLocalize';

import ThankYouMessage from '../../organisms/ThankYouMessage/ThankYouMessage';
import BubbleCtaSection from '../../organisms/BubbleCtaSection/BubbleCtaSection';

const appendSuccessIndicator = () => {
  const element = document.createElement('span');
  const mainContainer = document.querySelector('main');
  element.setAttribute('data-testid', 'success-download-asset');
  element.style.display = 'none';
  mainContainer.appendChild(element);
};

const DownloadAsset = ({ locale, resource }) => {
  const { data: rawData } = useSanityFetchDocuments(
    sanityClient,
    `//groq
    *[_type == "resourceDetailPage" && route->slug.current match "${resource}"]{
      title,
      "assetUrl": file.${locale}.asset->url 
    }[0]
  `
  );
  const data = useLocalize(rawData, locale);

  useEffect(() => {
    if (data) {
      const { assetUrl: asset, title } = data;
      const xhr = new XMLHttpRequest();
      xhr.onload = function downloadAsset() {
        const filename = `${title}.pdf`;
        const assetBlob = new Blob([new Uint8Array(xhr.response)]);
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(assetBlob, filename);
        } else {
          const reader = new FileReader();
          reader.onloadend = () => {
            const element = document.createElement('a');
            element.setAttribute('href', reader.result);
            element.setAttribute('download', filename);
            element.setAttribute('target', '_blank');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            appendSuccessIndicator();
          };
          reader.readAsDataURL(assetBlob);
        }
      };
      if (window.navigator.msSaveOrOpenBlob) {
        const backSlashAssetUrl = asset.replace(/\//g, '\\');
        xhr.open('GET', backSlashAssetUrl);
      } else {
        xhr.open('GET', asset);
      }
      xhr.responseType = 'arraybuffer';
      xhr.send();
    }
  }, [data]);
  return <div />;
};

DownloadAsset.propTypes = {
  resource: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

const ThankYouPage = ({ data: rawData, location, pageContext: { locale } }) => {
  const data = useLocalize(rawData, locale);

  const thankYouPageProps = {
    bubbleCtaSection: data.sanityThankYouPage._rawBubbleCtaSection,
    metadata: { ...data.sanityThankYouPage._rawRoute },
    id: data.sanityThankYouPage._id,
  };

  const thankYouMessageProps = {
    heading: data.sanityThankYouPage.heading,
    subtitle: data.sanityThankYouPage.subtitle,
  };

  const { resource } = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <Layout
      locale={locale}
      menuColor="gray"
      {...thankYouPageProps.metadata}
      bubbleCtaData={thankYouMessageProps.bubbleCtaSection}
      sanityId={thankYouPageProps.id}
    >
      <Helmet title={thankYouPageProps.title}>
        <meta name="robots" content="noindex" />
      </Helmet>
      {resource && <DownloadAsset resource={resource} locale={locale} />}
      <ThankYouMessage {...thankYouMessageProps} />
      {thankYouPageProps.bubbleCtaSection && (
        <BubbleCtaSection {...thankYouPageProps.bubbleCtaSection} />
      )}
    </Layout>
  );
};

ThankYouPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
};

export default ThankYouPage;

export const query = graphql`
  query($slug: String) {
    sanityThankYouPage(
      route: { enabled: { eq: true }, slug: { current: { eq: $slug } } }
    ) {
      _id
      _rawRoute(resolveReferences: { maxDepth: 100 })
      heading: _rawHeading(resolveReferences: { maxDepth: 100 })
      subtitle: _rawSubtitle(resolveReferences: { maxDepth: 100 })
      _rawBubbleCtaSection(resolveReferences: { maxDepth: 100 })
    }
  }
`;
