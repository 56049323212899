import { useReducer, useEffect, useRef } from 'react';
import debounce from 'lodash/fp/debounce';
import isEqual from 'lodash/fp/isEqual';
import usePrevious from './usePrevious';
import fetchStateReducer, {
  STATUSES,
  INITIAL_STATE,
  actionCreators,
} from '../reducers/fetchState';

const { failure, loading, success } = actionCreators;

export default function useSanityFetchDocuments(client, query) {
  const [state, dispatch] = useReducer(fetchStateReducer, INITIAL_STATE);
  const fetchQuery = useRef(
    debounce(500, async _query => {
      try {
        dispatch(loading());
        const data = await client.fetch(_query);
        dispatch(success(data));
      } catch (e) {
        dispatch(failure(e.message));
      }
    })
  ).current;
  const previousQuery = usePrevious(query);
  useEffect(() => {
    if (isEqual(previousQuery, query)) {
      return;
    }
    fetchQuery(query);
  }, [query, fetchQuery]);

  return {
    ...state,
    fetchQuery,
    loading: state.status === STATUSES.pending,
    resolved: state.status === STATUSES.resolved,
  };
}
